/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationStickerSearch = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationStickerSearch"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="var(--xdlColorIllustrationBackground)"
        fillRule="evenodd"
        d="m64.124 56.587-10.268-9.784c3.623-4.786 5.587-10.59 5.587-16.694 0-7.408-2.886-14.374-8.122-19.614-5.24-5.24-12.202-8.122-19.614-8.122-2.1 0-4.163.238-6.162.69C19.63 3.9 14.167 6.608 9.864 10.91c-5.24 5.24-8.126 12.206-8.126 19.615S4.624 44.899 9.86 50.139c5.24 5.24 12.202 8.122 19.614 8.122 2.1 0 4.16-.238 6.158-.691a27.4 27.4 0 0 0 11.197-4.205l9.184 9.64.03.035a4.35 4.35 0 0 0 3.094 1.28c.53 0 1.043-.098 1.523-.275q.18.018.363.019c1.17 0 2.27-.457 3.093-1.281a4.35 4.35 0 0 0 1.285-3.098 4.31 4.31 0 0 0-1.277-3.098"
        clipRule="evenodd"
      />
      <path
        fill="#A456F6"
        d="M55.322 30.525c0 14.275-11.573 25.847-25.848 25.847S3.626 44.8 3.626 30.525 15.2 4.677 29.474 4.677 55.322 16.25 55.322 30.525"
      />
      <path
        fill="#A456F6"
        d="m57.384 61.7-11.04-11.59a26 26 0 0 0 2.948-2.99l11.613 11.058c.971.971.971 2.55 0 3.521-.97.971-2.55.971-3.52 0"
      />
      <path
        fill="#1D2124"
        d="M60.4 62.09a2.49 2.49 0 0 0 .505-3.912L49.292 47.12a26 26 0 0 1-2.949 2.99l.747.783a26 26 0 0 1-5.49 3.1 26 26 0 0 1-6.335 1.728c11.487-2.63 20.057-12.912 20.057-25.196 0-14.275-11.573-25.848-25.848-25.848q-1.827.001-3.587.247C37.98 2.114 50.682 8.372 55.59 20.22c3.86 9.319 1.867 19.6-4.287 26.76L62.79 57.92a2.49 2.49 0 0 1-2.39 4.169"
      />
      <path
        fill="#fff"
        d="M29.474 53.153c12.498 0 22.63-10.131 22.63-22.629 0-12.497-10.132-22.628-22.63-22.628S6.845 18.026 6.845 30.524s10.132 22.63 22.63 22.63"
      />
      <path
        fill="#1D2124"
        d="M35.179 31.136a6.196 6.196 0 1 1-12.391 0 6.196 6.196 0 0 1 12.39 0"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="M29.172 15.606h-.378v6.472a25.9 25.9 0 0 0-8.414 1.47l-2.553-6.09-.349.146 2.546 6.072a26 26 0 0 0-10.621 7.336l-.11.124.11.125a25.94 25.94 0 0 0 19.58 8.934 25.94 25.94 0 0 0 19.58-8.934l.11-.125-.11-.124a26 26 0 0 0-10.906-7.439l2.503-5.967-.348-.146L37.3 23.45a25.9 25.9 0 0 0-8.128-1.371zm-.193 24.212a25.56 25.56 0 0 1-19.187-8.682 25.58 25.58 0 0 1 19.187-8.681 25.56 25.56 0 0 1 19.188 8.681 25.56 25.56 0 0 1-19.188 8.682"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="M29.474 53.331c-5.844 0-11.688-2.225-16.135-6.672-8.896-8.896-8.896-23.373 0-32.273 8.897-8.897 23.373-8.897 32.27 0s8.897 23.373 0 32.274c-4.446 4.45-10.29 6.671-16.135 6.671m0-45.239c-5.746 0-11.492 2.188-15.866 6.562-8.75 8.75-8.75 22.988 0 31.737 8.749 8.75 22.987 8.75 31.737 0s8.749-22.987 0-31.737c-4.375-4.374-10.121-6.562-15.87-6.562"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="M11.065 48.934c4.915 4.919 11.454 7.627 18.41 7.627 6.245 0 12.155-2.184 16.855-6.192l10.922 11.465a2.66 2.66 0 0 0 1.893.786 2.66 2.66 0 0 0 1.892-.786 2.66 2.66 0 0 0 .786-1.893c0-.714-.28-1.386-.79-1.896L49.55 47.105c3.86-4.654 5.96-10.456 5.96-16.58 0-6.955-2.708-13.49-7.627-18.41-4.915-4.918-11.454-7.627-18.409-7.627s-13.49 2.709-18.409 7.628C6.146 17.03 3.438 23.57 3.438 30.525s2.708 13.49 7.627 18.409m38.243-1.54a26.5 26.5 0 0 1-2.693 2.73l10.905 11.45c.431.43 1.009.668 1.625.668.615 0 1.194-.238 1.628-.672a2.29 2.29 0 0 0 .672-1.629c0-.615-.238-1.193-.672-1.628zM11.329 12.38c4.847-4.847 11.292-7.514 18.145-7.514 6.857 0 13.298 2.667 18.145 7.514 4.843 4.847 7.514 11.292 7.514 18.145 0 6.856-2.667 13.298-7.514 18.144-4.847 4.847-11.292 7.514-18.145 7.514-6.857 0-13.301-2.667-18.145-7.514-4.843-4.846-7.514-11.291-7.514-18.144S6.483 17.227 11.33 12.38"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IllustrationStickerSearch;
