import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { IconArrowRight } from '@xing-com/icons';
import {
  IllustrationStickerNetwork,
  IllustrationStickerSearch,
} from '@xing-com/illustrations';
import { BodyCopy, Headline, Hero } from '@xing-com/typography';

import * as Styled from './sunset-page.styled';

const SunsetPage: FC = () => {
  const coachingLink =
    '/search/members?sc_o=navigation_search_member_search&sc_o_PropActionOrigin=navigation_badge_no_badge&haves=coach';
  const knowledgeLink = '/profile/my_profile/skills/edit';
  const feedbackLink = `https://www.xing.com/support/contact/profile/profile`;

  return (
    <>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />
      <Styled.Container variant="expanded">
        <Styled.HeadGrid>
          <Hero size="small" sizeWide="medium">
            <FormattedMessage id="COACHES_SUNSET_HEADLINE" />
          </Hero>
        </Styled.HeadGrid>

        <Styled.MainGrid>
          <Styled.Card>
            <IllustrationStickerSearch size="large" />
            <Headline size="xlarge" sizeWide="xxlarge" noMargin>
              <FormattedMessage id="COACHES_SUNSET_COACHING_CARD_HEADLINE" />
            </Headline>
            <BodyCopy size="medium" sizeWide="large" fontWeight="regular">
              <FormattedMessage id="COACHES_SUNSET_COACHING_CARD_DESCRIPTION" />
            </BodyCopy>
            <Button to={coachingLink} variant="primary" size="medium">
              <FormattedMessage id="COACHES_SUNSET_COACHING_CARD_BUTTON" />
            </Button>
          </Styled.Card>
          <Styled.Card>
            <IllustrationStickerNetwork size="large" />
            <Headline size="xlarge" sizeWide="xxlarge" noMargin>
              <FormattedMessage id="COACHES_SUNSET_KNOWLEDGE_CARD_HEADLINE" />
            </Headline>
            <BodyCopy size="medium" sizeWide="large" fontWeight="regular">
              <FormattedMessage id="COACHES_SUNSET_KNOWLEDGE_CARD_DESCRIPTION" />
            </BodyCopy>
            <Button to={knowledgeLink} variant="secondary" size="medium">
              <FormattedMessage id="COACHES_SUNSET_KNOWLEDGE_CARD_BUTTON" />
            </Button>
          </Styled.Card>
        </Styled.MainGrid>
        <Styled.FooterGrid>
          <Headline size="large">
            <FormattedMessage id="COACHES_SUNSET_FEEDBACK_HEADLINE" />
          </Headline>
          <Styled.IconRightButton to={feedbackLink} icon={IconArrowRight}>
            <FormattedMessage id="COACHES_SUNSET_FEEDBACK_BUTTON" />
          </Styled.IconRightButton>
        </Styled.FooterGrid>
      </Styled.Container>
    </>
  );
};

export default SunsetPage;
